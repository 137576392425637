import React from 'react'
import { Link } from 'react-scroll'
import { Slider } from '@components'
import * as st from './plants.module.styl'

type Image = {
  legend: string,
  url: string,
  thumb: string
}

type Props = {
  items: Array<Image>,
  zoom: (imgs: Array<Image>, index: number) => void
}

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
]

export default ({ items, zoom }: Props) => <section className={st.core}>
  <h3>Confira as plantas</h3>
  <Slider className={st.slider} slidesToShow={3} slidesToScroll={3} dots responsive={responsive}>
    {items.map((item, key) =>
      <div key={key}>
        <div className={st.slide}>
          <div
            style={{ backgroundImage: `url(${item.thumb})` }}
            role="img"
            // @ts-ignore
            alt={item.legend}
            aria-label={item.legend}
            onClick={() => zoom(items, key)}>
          </div>
          <span>{item.legend}</span>
        </div>
      </div>
    )}
  </Slider>
  <Link to="Form" smooth duration={500} offset={-300}>Solicite mais informações</Link>
</section>