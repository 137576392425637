import React from 'react'
import * as st from './index.module.styl'
import img1 from '@assets/img/conceito-1.jpg'
import img2 from '@assets/img/conceito-2.jpg'

export default () =>
  <section className={st.core} id="Conceito">
    <img src={img1}/>
    <div className={st.text}>
      <h3>Conceito</h3>
      <p>
        Muito prazer, somos a Thera Empreendimentos. <br/> 
        Nosso objetivo é valorizar a experiência do morar. <br/> 
        Acreditamos que o lar é o reflexo de um estilo de vida, <br/> 
        e vemos em cada empreendimento uma oportunidade de <br/> 
        conectar as pessoas ao seu bairro, à sua cidade, ao seu lugar.
      </p>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div className={st.text}>
      <p>
        Por isso focamos em cada etapa do processo <br/>
        construtivo: priorizamos a escolha de <br/>
        localizações estratégicas, trabalhamos com <br/>
        projetos de arquitetos renomados e <br/>
        investimos em tecnologia e na mais alta <br/>
        qualidade dos materiais em nossos imóveis. <br/>
        <span></span>A Thera Empreendimentos se estabelece <br/>
        no mercado da construção civil em Florianópolis <br/>
        sustentada pela expertise do seu fundador no <br/>
        setor da incorporação imobiliária e execução <br/>
        de obras.
      </p>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <h4 className={st.founder}>Alisson Hoffmann, Fundador</h4>
    <ul className={st.numbers}>
      <li>
        <span>+ 130.000<span>M2</span></span>
        <span>de obras entregues</span>
      </li>
      <li>
        <span>+ 550</span>
        <span>apartamentos entregues</span>
      </li>
      <li>
        <span>+ 250</span>
        <span>salas comerciais e lojas entregues</span>
      </li>
      <li>
        <span>+ 200</span>
        <span>projetos realizados</span>
      </li>
    </ul>
    <p className={st.lastText}>
      Construímos nossa história com competência e solidez, sempre com o compromisso de entregar empreendimentos de excelência para nossos clientes.
    </p>
    <img src={img2}/>
  </section>