import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as st from './index.module.styl'
import logo from '@assets/svg/logo.svg'

type Link = {
  facebook: string,
  instagram: string
}

export default () => {
  const data: Array<Link> = useStaticQuery(graphql`
    query {
      allLinks {
        nodes {
          facebook
          instagram
        }
      }
    }
  `).allLinks.nodes

  return <footer className={st.core}>
    <img src={logo}/>
    <ul>
      <li>2021, todos os direitos reservados</li>
      <li>Av. Mauro Ramos, 1861, Sala 504 - Centro - Florianópolis/SC</li>
      {data[0] && Boolean(data[0].facebook) && <li><a className={st.facebook} href={data[0].facebook} target="_blank">Siga-nos no Instagram</a></li>}
      {data[0] && Boolean(data[0].instagram) && <li><a className={st.instagram} href={data[0].instagram} target="_blank">Siga-nos no Instagram</a></li>}
    </ul>
  </footer>
}