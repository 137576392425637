import React, { useState } from 'react'
import { Header, Footer, SEO } from '@components'
import ProjectHeader from './header'
import Form from './form'
import Location from './location'
import Images from './images'
import Plants from './plants'
import Lightbox from 'lightbox-react'
import * as st from './index.module.styl'
import 'lightbox-react/style.css'

function videoId(url: string) {
  if (url.includes('youtu.be'))
    return url.split('youtu.be/')[1]
  return url.split('watch?v=')[1]
}

type Image = {
  legend: string,
  url: string,
  thumb: string
}

type Props = {
  pageContext: {
    name: string,
    logoImage: string,
    form_title: string,
    form_button_text: string,
    folder: string,
    city: string,
    neighborhood: string,
    slug: string,
    thumbImage: string,
    bannerImage: string,
    latitude: string,
    longitude: string,
    reference_points: string,
    concept_word: string,
    concept_title: string,
    concept_description: string,
    address: string,
    images_perspectives: Array<Image>,
    images_plants: Array<Image>,
    video: string
  }
}

export default ({ pageContext: data }: Props) => {
  const [ zoomImgs, setZoomImgs ] = useState([])
  const [ zoomIndex, setZoomIndex ] = useState(0)

  const zoom = (imgs: Array<Image>, index) => {
    setZoomIndex(index)
    setZoomImgs(imgs.map(i => i.url))
  }

  return <>
    <SEO title="MY SQUARE - Thera Empreendimentos" description="MY SQUARE - Studios, 2 e 3 dormitórios com metragem de 49m² a 102m² no Centro de Florianópolis." />
    <Header/>
    <ProjectHeader
      bannerImage={data.bannerImage}
      latitude={data.latitude}
      longitude={data.longitude}/>
    <section className={st.core}>
      <div className={st.conceptWord}>{data.concept_word}</div>
      <div className={st.conceptBody}>
        <h3>{data.concept_title}</h3>
        <p dangerouslySetInnerHTML={{__html: data.concept_description.replace(/\n/g, '<br/>')}}/>
      </div>
      <Form name={data.name} form_title={data.form_title} form_button_text={data.form_button_text} folder={data.folder} logoImage={data.logoImage}/>
    </section>
    {data.video && <div className={st.video}>
      <iframe src={`https://www.youtube.com/embed/${videoId(data.video)}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>}
    <Location
      address={data.address}
      neighborhood={data.neighborhood}
      city={data.city}
      reference_points={data.reference_points}
      latitude={data.latitude}
      longitude={data.longitude}/>
    <Images items={data.images_perspectives} zoom={zoom}/>
    <Plants items={data.images_plants} zoom={zoom}/>
    <Footer/>
    {Boolean(zoomImgs.length) && <Lightbox
      mainSrc={zoomImgs[zoomIndex]}
      onCloseRequest={() => setZoomImgs([])}
      prevSrc={(zoomImgs.length - 1) && zoomImgs[(zoomIndex + zoomImgs.length - 1) % zoomImgs.length]}
      nextSrc={(zoomImgs.length - 1) && zoomImgs[(zoomIndex + 1) % zoomImgs.length]}
      onMovePrevRequest={() => setZoomIndex((zoomIndex + zoomImgs.length - 1) % zoomImgs.length)}
      onMoveNextRequest={() => setZoomIndex((zoomIndex + 1) % zoomImgs.length)}
    />}
  </>
}