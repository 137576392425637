import React from 'react'
//import formatcoords from 'formatcoords'
import * as st from './header.module.styl'

type Props = {
  bannerImage: string,
  latitude: string,
  longitude: string
}

export default ({ bannerImage, latitude, longitude }: Props) => {
  //const coords = formatcoords(Number.parseFloat(latitude), Number.parseFloat(longitude)).format('DD M\' s\'\' X', { latLonSeparator: ',', decimalPlaces: 0 }).replace(/W/, 'O').split(',')

  return <div style={{ backgroundImage: `url(${bannerImage})` }} className={st.core}>
    {/*<div className={st.coordinates}>
      Lat {coords[0]}<br/>
      Lon {coords[1]}
    </div>*/}
  </div>
}