import React from 'react'
import Slider from 'react-slick'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby'
import { Header } from '@components'
import { navItems } from '@data'
import * as st from './index.module.styl'
import logo from '@assets/svg/logo.svg'
import { graphql, useStaticQuery } from 'gatsby'

type Banner = {
  name: string,
  image: string,
  mobile: string,
  url: string,
  external: string
}

export default () => {
  const data: Array<Banner> = useStaticQuery(graphql`
    query {
      allShowcases {
        nodes {
          name
          image
          mobile
          url
          external
        }
      }
    }
  `).allShowcases.nodes

  /*useEffect(() => {
    api.requestData('get', 'showcases').then(data => console.log(data))
  }, [])*/

  const Slide = ({ mobile, url, external, image }) => {
    if (!url)
      return <div className={st.slide} style={{ backgroundImage: `url(${image})` }}>
        <div style={{ backgroundImage: `url(${mobile})` }}></div>
      </div>
    if (external)
      return <a className={st.slide} style={{ backgroundImage: `url(${image})` }} href={url} target="_blank">
        <div style={{ backgroundImage: `url(${mobile})` }}></div>
      </a>
    return <Link className={st.slide} style={{ backgroundImage: `url(${image})` }} to={url.replace('https://www.theraempreendimentos.com.br', '')}>
      <div style={{ backgroundImage: `url(${mobile})` }}></div>
    </Link>
  }

  return <>
    <Header home/>
    <section className={st.core} id="Home">
      <Slider>
        {data.map((banner, key) =>
          <div key={key}>
            <Slide {...banner}/>
          </div>
        )}
      </Slider>
      <div className={st.heading}>
        <div>
          <img src={logo} height={76} />
          <nav>
            <ul>
              {navItems.map((item, index) => <li key={index}>
                <ScrollLink to={item.name} smooth duration={500}>{item.name}</ScrollLink>
              </li>)}
            </ul>
          </nav>
        </div>
      </div>
    </section>
  </>
}