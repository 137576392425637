import React from 'react'
import Helmet from 'react-helmet'

export default ({
  title = 'Thera Empreendimentos',
  description = 'Acesse nosso site e conheça nosso conceito e projetos na Grande Florianópolis.'
}: {
  title?: string
  description?:string
}) => <Helmet>
  <title>{title}</title>
  <meta property="og:locale" content="pt_BR"/>
  <meta property="og:title" content={title}/>
  <meta property="og:site_name" content="Thera Empreendimentos"/>
  <meta property="og:description" content={description}/>
  <meta name="description" content={description}/>
  <meta property="og:type" content="website"/>
  <meta property="og:image" content="/opengraph.jpg"/>
  <meta property="og:image:type" content="image/jpeg"/>
  <meta property="og:image:width" content="1200"/>
  <meta property="og:image:height" content="628"/>
  <meta property="og:image:height" content="628"/>
  <link rel="icon" type="image/x-icon" href="/favicon.ico"/>
</Helmet>