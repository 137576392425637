import React from 'react'
import GoogleMapReact from 'google-map-react'
import * as st from './location.module.styl'

type Props = {
  address: string,
  neighborhood: string,
  city: string,
  reference_points: string,
  latitude: string,
  longitude: string
}

const Marker = ({ lat, lng }) => <div className={st.marker}></div>

export default ({ address, neighborhood, city, reference_points, latitude, longitude }: Props) =>
  <section className={st.core}>
    <h3>{address} | {neighborhood} | {city}</h3>
    <div className={st.mapContainer}><div>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAwxoGl33FO9yYDxrmAzQUSdWoSXr1UoE4' }}
        defaultCenter={{
          lat: Number.parseFloat(latitude),
          lng: Number.parseFloat(longitude)
        }}
        defaultZoom={14}
      >
        <Marker lat={Number.parseFloat(latitude)} lng={Number.parseFloat(longitude)}/>
      </GoogleMapReact>
    </div></div>
    <h3>Pontos de referência</h3>
    <ul dangerouslySetInnerHTML={{__html: '<li>'+reference_points.replace(/\r\n/g, '</li><li>')+'</li>'}}></ul>
  </section>