import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'
import { navItems } from '@data'
import cn from 'classnames'
import * as st from './index.module.styl'
import logo from '@assets/svg/logo.svg'

type Props = { home?: boolean }

export default ({ home = false }: Props) => {
  const [open, setOpen] = useState(false)
  const [hide, setHide] = useState(home)

  home && useEffect(() => {
    const home = document.getElementById('Home')
    const onScroll = (newScrollPos: number) => {
      setHide(newScrollPos < .8*home.offsetHeight)
      if (newScrollPos < .8*home.offsetHeight && open)
        setOpen(false)
    }
    const listener = (e) => onScroll((e.target as any).documentElement.scrollTop)
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [open]);

  return <header className={cn(st.core, hide && st.hide, open && st.open)}>
    <div>
      {home ?
        <ScrollLink to="Home" smooth duration={500} className={st.img} onClick={() => setOpen(false)}><img src={logo}/></ScrollLink>
      :
        <Link to="/#Home" className={st.img} onClick={() => setOpen(false)}><img src={logo}/></Link>
      }
      <div className={st.hamburger} onClick={() => setOpen(!open)}></div>
      <nav>
        <ul>
          {navItems.map((item, index) => <li key={index}>
            {home ?
              <ScrollLink to={item.name} smooth duration={500} onClick={() => setOpen(false)}>{item.name}</ScrollLink>
            :
              <Link to={`/#${item.name}`} onClick={() => setOpen(false)}>{item.name}</Link>
            }
          </li>)}
        </ul>
      </nav>
    </div>
  </header>
}