import React, { FormEvent, useState } from 'react'
import InputMask from 'react-input-mask'
import { navigate } from 'gatsby'
import axios from 'axios'
import cn from 'classnames'
import * as st from './index.module.styl'

type Errors = {
  nome?: boolean,
  telefone?: boolean,
  email?: boolean,
  assunto?: boolean,
  mensagem?: boolean
}

export default () => {
  const defaultErrors: Errors = {}
  const [ errors, setErrors ] = useState(defaultErrors)
  const [ buttonLabel, setButtonLabel ] = useState('Enviar')
  const [ data, setData ] = useState({
    nome: '',
    telefone: '',
    email: '',
    assunto: '',
    mensagem: '',
    identificador: 'formulario-site-thera',
    token_rdstation: '5f6e806520fed86a13d57ca585b0fa39'
  })

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    const newErrors = {...errors}
    let hasError = false
    if (data.nome.length === 0) {
      hasError = true
      newErrors.nome = true
    }
    if (data.telefone.length < 14) {
      hasError = true
      newErrors.telefone = true
    }
    if (data.email.length === 0) {
      hasError = true
      newErrors.email = true
    }
    if (data.assunto.length === 0) {
      hasError = true
      newErrors.assunto = true
    }
    if (data.mensagem.length === 0) {
      hasError = true
      newErrors.mensagem = true
    }

    setErrors(newErrors)

    if (!hasError) {
      setButtonLabel('Enviando...')
      axios.post('https://www.rdstation.com.br/api/1.3/conversions', data, { headers: {
        'Content-type': 'application/json'
      }}).then((data) => {
        setButtonLabel('Enviar')
        data.status === 200 && navigate('/sucesso', { state: {} })
      })
    }
  }

  return <section className={st.core} id="Contato">
    <div>
      <h3>Contato</h3>
      <p>
        Fale conosco através do telefone <a href="tel:4833800230">(48) 3380-0230</a> ou envie mensagem através do formulário abaixo.
        <br/><br/>
        Av. Mauro Ramos, 1861, Sala 504 - Centro, Florianópolis
      </p>
    </div>
    <form onSubmit={onSubmit}>
      <input name="nome" placeholder="Nome" onInput={() => setErrors({...errors, nome: false})} className={cn(errors.nome && st.error)} value={data.nome} onChange={(e) => setData({...data, nome: e.target.value})}/>

      <InputMask mask={data.telefone.length > 14 ? "(99) 99999-9999" : "(99) 9999-99999"} maskChar={null} name="telefone" type="tel" placeholder="Telefone" onInput={() => setErrors({...errors, telefone: false})} className={cn(errors.telefone && st.error)} value={data.telefone} onChange={(e) => setData({...data, telefone: e.target.value})}/>

      <input name="email" type="email" placeholder="Email" onInput={() => setErrors({...errors, email: false})} className={cn(errors.email && st.error)} value={data.email} onChange={(e) => setData({...data, email: e.target.value})}/>

      <input name="assunto" placeholder="Assunto" onInput={() => setErrors({...errors, assunto: false})} className={cn(errors.assunto && st.error)} value={data.assunto} onChange={(e) => setData({...data, assunto: e.target.value})}/>

      <div className={st.textarea}>
        <textarea name="mensagem" placeholder="Mensagem" onInput={() => setErrors({...errors, mensagem: false})} className={cn(errors.mensagem && st.error)} value={data.mensagem} onChange={(e) => setData({...data, mensagem: e.target.value})}></textarea>
      </div>

      <div className={st.ws}></div>
      <button>{buttonLabel}</button>
    </form>
  </section>
}