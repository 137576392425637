import React, { FormEvent, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import InputMask from 'react-input-mask'
import axios from 'axios'
import { navigate } from 'gatsby'
import cn from 'classnames'
import * as st from './form.module.styl'

type Props = {
  name: string,
  form_title: string,
  form_button_text: string,
  folder: string,
  logoImage: string
}

type Errors = {
  nome?: boolean,
  email?: boolean,
  telefone?: boolean
}

type Link = {
  whatsapp: string
}

export default ({ name, form_title, form_button_text, folder, logoImage }: Props) => {
  const defaultErrors: Errors = {}
  const [ errors, setErrors ] = useState(defaultErrors)
  const [ buttonLabel, setButtonLabel ] = useState(form_button_text)
  const [ data, setData ] = useState({
    nome: '',
    telefone: '',
    email: '',
    projeto: name,
    identificador: 'formulario-projeto-thera',
    token_rdstation: '5f6e806520fed86a13d57ca585b0fa39'
  })

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    const newErrors = {...errors}
    let hasError = false
    if (data.nome.length === 0) {
      hasError = true
      newErrors.nome = true
    }
    if (data.telefone.length < 14) {
      hasError = true
      newErrors.telefone = true
    }
    if (data.email.length === 0) {
      hasError = true
      newErrors.email = true
    }

    setErrors(newErrors)

    if (!hasError) {
      setButtonLabel('Enviando...')
      axios.post('https://www.rdstation.com.br/api/1.3/conversions', data, { headers: {
        'Content-type': 'application/json'
      }}).then((data) => {
        setButtonLabel(form_button_text)
        data.status === 200 && navigate('/sucesso', { state: {} })
      })
    }
  }

  const [ wppInfix, setWppInfix ] = useState('web')
  const links: Array<Link> = useStaticQuery(graphql`
    query {
      allLinks {
        nodes {
          whatsapp
        }
      }
    }
  `).allLinks.nodes
  useEffect(() => {
    const mobile = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ].some(item => navigator.userAgent.match(item))
    mobile && setWppInfix('api')
  }, [])

  return <aside id="Form">
    <div className={st.core}>
      {Boolean(logoImage) && <img src={logoImage}/>}
      <h1><span>{name}</span></h1>
      <h2 dangerouslySetInnerHTML={{ __html: form_title.replace(/,/g, ',<br/>') }}/>
      <p>Receba mais informações sem compromisso</p>
      <form onSubmit={onSubmit}>
        <label htmlFor="nome" className={cn(errors.nome && st.error)}>Nome:</label>
        <input name="nome" id="nome" onInput={() => setErrors({...errors, nome: false})} className={cn(errors.nome && st.error)} value={data.nome} onChange={(e) => setData({...data, nome: e.target.value})} placeholder="Nome"/>

        <label htmlFor="email" className={cn(errors.email && st.error)}>Email:</label>
        <input type="email" name="email" id="email" onInput={() => setErrors({...errors, email: false})} className={cn(errors.email && st.error)} value={data.email} onChange={(e) => setData({...data, email: e.target.value})}  placeholder="Email"/>

        <label htmlFor="telefone" className={cn(errors.nome && st.error)}>Telefone:</label>
        <InputMask mask={data.telefone.length > 14 ? "(99) 99999-9999" : "(99) 9999-99999"} maskChar={null} type="tel" name="telefone" id="telefone" onInput={() => setErrors({...errors, telefone: false})} className={cn(errors.telefone && st.error)} value={data.telefone} onChange={(e) => setData({...data, telefone: e.target.value})}  placeholder="Telefone"/>

        <button>{buttonLabel}</button>
      </form>
      {data[0] && Boolean(data[0].whatsapp) && <a href={`https://${wppInfix}.whatsapp.com/send?phone=${data[0].whatsapp}`} target="_blank">Chamar no WhatsApp</a>}
    </div>
    <div className={st.folder}>
      <ul>
        <li>Folder</li>
        <li>Press</li>
        <li>Product</li>
      </ul>
      <a href={folder} target="_blank">Download folder</a>
    </div>
  </aside>
}