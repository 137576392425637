import React from 'react'
import { Link } from 'gatsby'
import * as st from './index.module.styl'

type Props = { title: string, description: string }

export default ({ title, description }: Props) => <section className={st.core}>
  <h1>{title}</h1>
  <p>{description}</p>
  <Link to="/">Voltar para o site</Link>
</section>