import React from 'react'
import { Slider } from '@components'
import * as st from './index.module.styl'
import { Link, graphql, useStaticQuery } from 'gatsby'

type Project = {
  name: string,
  city: string,
  neighborhood: string,
  slug: string,
  thumbImage: string
}

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      dots: true
    }
  }
]

export default () => {
  const data: Array<Project> = useStaticQuery(graphql`
    query {
      allProjects {
        nodes {
          name
          city
          neighborhood
          slug
          thumbImage
        }
      }
    }
  `).allProjects.nodes

  return <section className={st.core} id="Projetos">
    <h3>Projetos</h3>
    <Slider className={st.slider} responsive={responsive}>
      {data.map((project, key) =>
        <div key={key}>
          <div
            className={st.slide}
            style={{ backgroundImage: `url(${project.thumbImage})` }}
          >
            <Link to={`/projeto/${project.slug}`}>
              {project.name && <><span>{project.name}</span><br/></>}
              {(project.city || project.neighborhood) && <span>
                {project.city} {project.neighborhood}
              </span>}
            </Link>
          </div>
        </div>
      )}
    </Slider>
  </section>
}