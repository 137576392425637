import React from 'react'
import * as st from './index.module.styl'
import img1 from '@assets/img/polaroids-1.jpg'
import img2 from '@assets/img/polaroids-2.jpg'
import img3 from '@assets/img/polaroids-3.jpg'

export default () =>
  <section className={st.core}>
    <div className={st.text}>
      <div><span>Boa arquitetura não é apenas</span></div>
      <div><span>forma. Boa arquitetura é a</span></div>
      <div><span>interação entre forma e vida.</span></div>
      <div><span>É assim que pensamos.</span></div>
    </div>
    <div className={st.content}>
      <img src={img1}/>
      <img src={img3}/>
      <img src={img2}/>
    </div>
  </section>