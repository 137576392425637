type NavItem = { name: string }

const navItems : Array<NavItem> = [{
  name: 'Conceito'
},
{
  name: 'Projetos'
},
{
  name: 'Contato'
}] 

export default navItems